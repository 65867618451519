import React from 'react'
import Login from '../components/Login'

function LoginScreen() {
  return (
    <div className='login_Screen'>
        <Login/>
    </div>
  )
}

export default LoginScreen
