import React from 'react';
import Navbar from '../components/Navbar';
import Data from '../components/Data';
import Inferences from '../components/Inferences';

function DashboardScreen() {
  return (
    <div className='dashboard_Launch'>
      <Navbar />
      <Data />
      <Inferences />
    </div>
  );
}

export default DashboardScreen;
