import React, { useState } from 'react';
import "../components/Viewer.css";

function ViewerScreen() {
  const [gridSize, setGridSize] = useState(1); // Initial grid size
  const [currentPage, setCurrentPage] = useState(0); // Current page of cameras

  const cameras = [
    "https://images.unsplash.com/photo-1515259387710-51e175f9ec6d?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1465447142348-e9952c393450?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1516203294340-5ba5f612dc6a?q=80&w=1578&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1515259387710-51e175f9ec6d?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1465447142348-e9952c393450?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1516203294340-5ba5f612dc6a?q=80&w=1578&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1515259387710-51e175f9ec6d?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1465447142348-e9952c393450?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1516203294340-5ba5f612dc6a?q=80&w=1578&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1515259387710-51e175f9ec6d?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1465447142348-e9952c393450?q=80&w=1548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1516203294340-5ba5f612dc6a?q=80&w=1578&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ];

  // Function to handle grid size change
  const handleGridSizeChange = (size) => {
    setGridSize(size);
    setCurrentPage(0); // Reset to first page when changing grid size
  };

  // Function to handle navigation to previous page
  const goToPreviousPage = () => {
    setCurrentPage(currentPage => currentPage - 1);
  };

  // Function to handle navigation to next page
  const goToNextPage = () => {
    setCurrentPage(currentPage => currentPage + 1);
  };

  // Calculate the start and end indices of cameras to display
  const startIndex = currentPage * gridSize;
  const endIndex = Math.min(startIndex + gridSize, cameras.length);

  // Determine the CSS class based on the selected grid size
  let gridClass = '';
  if (gridSize === 1) {
    gridClass = 'single-camera';
  } else if (gridSize === 4) {
    gridClass = 'four-camera';
  } else if (gridSize === 9) {
    gridClass = 'nine-camera';
  }

  // Calculate the number of empty grid items
  const emptyGridItems = gridSize - (endIndex - startIndex);

  return (
    <div className='viewer'>
      <div className={`viewer_Content ${gridClass}`}>
        {/* Render cameras based on the selected grid size and current page */}
        <div className="grid-container">
          {cameras.slice(startIndex, endIndex).map((camera, index) => (
            <div className="grid-item" key={index} style={{ backgroundImage: `url(${camera})` }}>
              {/* Empty div for background image */}
            </div>
          ))}
          {/* Add empty grid items if necessary */}
          {emptyGridItems > 0 && Array.from({ length: emptyGridItems }).map((_, index) => (
            <div className="grid-item empty" key={`empty-${index}`} />
          ))}
        </div>
      </div>
      {/* Grid size selection buttons */}
      <div className="grid-controls">
        <button onClick={() => handleGridSizeChange(1)}>1 Camera</button>
        <button onClick={() => handleGridSizeChange(4)}>4 Cameras</button>
        <button onClick={() => handleGridSizeChange(9)}>9 Cameras</button>
        {/* Add more grid size buttons as needed */}
      </div>
      {/* Navigation buttons */}
      <div className="navigation-controls">
        <button onClick={goToPreviousPage} disabled={currentPage === 0}>Previous</button>
        <button onClick={goToNextPage} disabled={endIndex >= cameras.length}>Next</button>
      </div>
    </div>
  );
}

export default ViewerScreen;
