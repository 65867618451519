import React, { useState, useEffect } from 'react';
import "../components/Data.css";
import { Link } from 'react-router-dom';

function Data() {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [, setIdUser] = useState(null);
    const [profileImage, setProfileImage] = useState(null);

    useEffect(() => {
        const fetchUserDataFromSession = () => {
            try {
                const userData = JSON.parse(sessionStorage.getItem('user'));
                if (userData) {
                    setIdUser(userData.id_user);
                    setEmail(userData.email);
                    setFirstName(userData.user_name);
                    setLastName(userData.last_name || ''); // Adjust as necessary
                    setProfileImage(userData.user_image);
                } else {
                    console.error('User data not found in session.');
                }
            } catch (error) {
                console.error('Error fetching user data from session:', error);
            }
        };

        fetchUserDataFromSession();
    }, []); // Run only once after component mounts

    return (
        <div className='data'>
            <div className='data_Profile'>
                <div className='data_Name'>
                    <p>{firstName} {lastName}</p>
                </div>
                <Link to='/profile' className='navbar_Profile'>
                    {profileImage ? (
                        <img className='profile_Image' src={profileImage} alt='Profile' />
                    ) : (
                        <svg width='22' height='22' viewBox='0 0 22 22'>
                            {/* SVG content */}
                        </svg>
                    )}
                </Link>
                <div className='data_Info'>
                    <p>{email}</p>
                </div>
                <Link to="/profile" className="data_Button">
                    Editar perfil
                </Link>
            </div>
            <div className='data_Plan'>
                <h3>Plano Plus</h3>
                <button>ATIVO</button>
                <p>Vencimento: dia 20</p>
                <p>Forma de pagamento: crédito</p>
                <p>Usuários ativos: crédito</p>
                <div className='data_Button'>
                    Alterar plano
                </div>
            </div>
        </div>
    );
}

export default Data;
