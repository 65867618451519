export const criarElementoDeVideo = (idDaDiv) => {
    const video = document.createElement('video');
    video.id = "remoteVideo-" + idDaDiv;
    video.controls = true;
    video.muted = true;
    video.autoplay = true;

    const videoContainer = document.createElement('div');
    videoContainer.className = 'camera';
    videoContainer.appendChild(video);
  
    return videoContainer;
  };
  