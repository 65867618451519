import React from 'react'
import Navbar from '../components/Navbar'
import Profile from '../components/Profile'

function SettingsScreen() {
  return (
    <div className='settings'>
        <Navbar/>
        <Profile/>
    </div>
  )
}

export default SettingsScreen
