import React, { useState } from 'react';
import '../components/DevicesBar.css'; // Import your CSS file for styling the DevicesBar
import PopUp from './PopUp'; // Import the PopUp component

function DevicesBar() {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    
    <div className='devices-bar'>
      <div className='devices-title'>
        <h1>Manage your devices</h1>
        <p>Manage all your devices here</p>
      </div>
      <button className='add-device-button'onClick={openPopup}>+ Add Device</button>
      {showPopup && <PopUp onClose={closePopup} />}
    </div>
  );
}

export default DevicesBar;
