import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { addCamera } from '../functions/webrtcUtils';
import { criarElementoDeVideo } from '../functions/videoUtils';
import axios from 'axios';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
} from "@vis.gl/react-google-maps";
import { initializeWebSocket } from '../functions/websocketUtils';  
import "../components/Map.css"
import FilterDevice from "../components/FilterDevice"

function Maps() {
    const [, setDevices] = useState([]);
    const [cameras, setCameras] = useState([]);
    const [filteredDevices, setFilteredDevices] = useState([]);
    const [openStreams, setOpenStreams] = useState([]);
    const webRTCAdaptors = useRef({});
    const socketRef = useRef(null);
    const [showFilter, setShowFilter] = useState(false);

    const storedUser = useMemo(() => JSON.parse(sessionStorage.getItem('user')), []);

    const fetchDevices = useCallback(async (status = 'all') => {
        if (!storedUser || !storedUser.id_user) return;
        try {
            let url = `https://spyskytech.net/api/get_devices/${storedUser.id_user}`;
            if (status !== 'all') url += `?status=${status}`;

            const response = await axios.get(url);
            const devicesData = response.data;
            setDevices(devicesData);

            const cameraPromises = devicesData.map(async (device) => {
                if (device.status === 'online') {
                    try {
                        const camera = await addCamera(device, webRTCAdaptors, setCameras, criarElementoDeVideo);
                        if (camera && camera.videoContainer instanceof Node) {
                            return camera;
                        } else {
                            console.error('Invalid camera object:', camera);
                            return null;
                        }
                    } catch (error) {
                        console.error('Error adding camera:', error);
                        return null;
                    }
                } else {
                    return {
                        id: device.streamid,
                        id_device: device.id_device,
                        device_model: device.device_model,
                        device_name: device.device_name,
                        lat: device.lat,
                        long: device.long,
                        status: device.status,
                        videoContainer: null,
                    };
                }
            });

            const cameraResults = await Promise.all(cameraPromises);
            setCameras(cameraResults.filter(camera => camera !== null));
            setFilteredDevices(cameraResults.filter(camera => camera !== null));

        } catch (error) {
            console.log(error);
        }
    }, [storedUser]);

    useEffect(() => {
        fetchDevices();
        if (storedUser) {
            socketRef.current = initializeWebSocket(storedUser, (device) => addCamera(device, webRTCAdaptors, setCameras, criarElementoDeVideo));
        }
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [fetchDevices, storedUser]);

    const handleMarkerClick = (index) => {
        setOpenStreams(prevOpenStreams => {
            const isOpen = prevOpenStreams.includes(index);
            if (isOpen) {
                return prevOpenStreams.filter(i => i !== index);
            } else {
                return [...prevOpenStreams, index];
            }
        });
    };

    const getPinColor = (status) => {
        return status === 'online' ? '#1E962B' : '#364AB2';
    };

    const calculateMarkerPosition = (camera, index) => {
        const baseOffset = 0.00005; // Adjust the value as necessary for the desired spacing
        const angle = (index / 10) * Math.PI * 2; // Distribute the markers in a circle

        const latOffset = baseOffset * Math.cos(angle);
        const lngOffset = baseOffset * Math.sin(angle);

        return {
            lat: parseFloat(camera.lat) + latOffset,
            lng: parseFloat(camera.long) + lngOffset,
        };
    };

    const handleStatusFilterChange = (status) => {
        if (status === 'all') {
            setFilteredDevices(cameras);
        } else {
            const filtered = cameras.filter(camera => camera.status === status);
            setFilteredDevices(filtered);
        }
        setOpenStreams([]); // Close all open info windows when filtering
    };

    return (
        <APIProvider apiKey="AIzaSyD6xyMDrd62_iPVWn2KvmrGd704aTFi3rg">
            <div className='map_View'>
                <div className="filter_Buttons">
                    <button onClick={() => handleStatusFilterChange('all')}>All</button>
                    <button onClick={() => handleStatusFilterChange('online')}>ON</button>
                    <button onClick={() => handleStatusFilterChange('offline')}>OFF</button>
                </div>
                <div className='filter_Devices'>
                    <button onClick={() => setShowFilter(!showFilter)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                        <path opacity={0.7} d="M4 6H14M1.5 1H16.5M6.5 11H11.5" stroke="#111" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    </button>
                </div>

                {showFilter && (
                    <FilterDevice
                        onClose={() => setShowFilter(false)}
                        onConfirm={(filters) => {
                            const selectedTypes = Object.keys(filters).filter(key => filters[key]);
                            if (selectedTypes.length === 0) {
                                setFilteredDevices(cameras);
                            } else {
                                const filtered = cameras.filter(camera => selectedTypes.includes(camera.device_model));
                                setFilteredDevices(filtered);
                            }
                            setShowFilter(false);
                        }}
                    />
                )}

                <Map
                    mapId="437e683027e03e32"
                    defaultCenter={{ lat: -23.56036437857493, lng: -46.64199109550119 }}
                    defaultZoom={6}
                >
                    {filteredDevices.map((camera, index) => (
                        <React.Fragment key={camera.id_device}>
                            <AdvancedMarker
                                position={calculateMarkerPosition(camera, index)}
                                onClick={() => handleMarkerClick(index)}
                            >
                                <Pin
                                    background={getPinColor(camera.status)}
                                    borderColor={"white"}
                                    glyphColor={"white"}
                                    scale={1}
                                />
                            </AdvancedMarker>
                        </React.Fragment>
                    ))}
                </Map>
                
                {openStreams.length > 0 && (
                    <div className='info_Infinite'>
                        {openStreams.map((index) => {
                            const camera = filteredDevices[index];
                            return (
                                <div className='Info_Camera' key={camera.id_device}>
                                    <div ref={(el) => {
                                        if (el && el.childNodes.length === 0 && camera.videoContainer instanceof Node) {
                                            el.appendChild(camera.videoContainer);
                                            const video = camera.videoContainer.querySelector('video');
                                            if (video) {
                                                video.play().catch(error => console.error('Autoplay error:', error));
                                            }
                                        }
                                    }} />
                                    <p>{camera.device_name}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </APIProvider>
    );
}

export default Maps;

export const sortDevices = (devices) => {
    const onlineDevices = devices.filter(device => device.status === 'online');
    const offlineDevices = devices.filter(device => device.status !== 'online');
    return [...onlineDevices, ...offlineDevices];
};

export const handleStatusFilterChange = (status, devices, setFilteredDevices) => {
    if (status === 'all') {
        setFilteredDevices(devices);
    } else {
        const filtered = devices.filter(device => device.status === status);
        setFilteredDevices(filtered);
    }
};

export const handleTypeFilterChange = (type, devices, setFilteredDevices) => {
    if (type === 'all') {
        setFilteredDevices(devices);
    } else {
        const filtered = devices.filter(device => device.device_model === type);
        setFilteredDevices(filtered);
    }
};