import React from 'react'
import Register from '../components/Register'

function RegisterScreen() {
  return (
    <div className='register_Screen'>
        <Register/>
    </div>
  )
}

export default RegisterScreen
