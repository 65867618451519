import React from 'react'
import Devices from '../components/Devices'
import Navbar from '../components/Navbar'
import DevicesBar from '../components/DevicesBar'
import '../components/Devices.css'

function DevicesScreen() {
  return (
    
    <div className='devices_Screen'>
        <Navbar/>
        <DevicesBar/>
        <Devices/>
    </div>
  )
}

export default DevicesScreen
