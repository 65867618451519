import React, { useState } from 'react';
import "../components/FilterDevice.css";

function FilterDevice({ onClose, onConfirm }) {
  const [selectedFilters, setSelectedFilters] = useState({
    aerialRobot: false,
    roverRobot: false,
    waterRobot: false,
    smartphones: false,
    tablets: false,
    bodyWornCam: false,
    cameraIP: false,
  });

  const handleFilterChange = (e) => {
    const { name, checked } = e.target;
    setSelectedFilters({
      ...selectedFilters,
      [name]: checked,
    });
  };

  const handleConfirm = () => {
    onConfirm(selectedFilters);
  };

  return (
    <div className="modal_Overlay">
      <div className="modal_Content">
        <div className="modal_Header">
          <h2>Select Your Filter</h2>
        </div>
        <div className="modal_Body">
          {Object.keys(selectedFilters).map((filter) => (
            <label key={filter} className="filter_Label">
              <span className="filter_Text">{filter.replace(/([A-Z])/g, ' $1')}</span>
              <input className='filter_Checkbox'
                type="checkbox"
                name={filter}
                checked={selectedFilters[filter]}
                onChange={handleFilterChange}
              />
            </label>
          ))}
        </div>
        <div className="modal_Footer">
          <button className="modal_Button_Confirm" onClick={handleConfirm}>
            Confirm
          </button>
          <button className="modal_Button_Close" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default FilterDevice;
