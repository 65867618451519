import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyArNsDb1Yg6HMxlUUblQWyajRdL_sR9XrI",
    authDomain: "spyskytech-web.firebaseapp.com",
    projectId: "spyskytech-web",
    storageBucket: "spyskytech-web.appspot.com",
    messagingSenderId: "324661758743",
    appId: "1:324661758743:web:e2a416a1a516a1314e4552",
    measurementId: "G-YWLFPERF3M"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth };
export default db;