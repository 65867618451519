export const initializeWebSocket = (user, addCamera) => {
    const socket = new WebSocket('wss://spyskytech.net/api/ws');
  
    socket.onopen = () => {
      console.log('WebSocket connection established');
      socket.send(JSON.stringify({ type: 'subscribe', userId: user.id_user }));
    };
  
    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'new_camera_online') {
        addCamera(message.device);
      }
    };
  
    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
  
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    return socket;
  };
  