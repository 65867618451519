import React, { useState, useEffect } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import "../components/Inferences.css";

function Inferences() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://mobile.viewer.spyskytech.com/inferences');
        if (response.status === 200) {
          const result = await response.json();
          setData(result);
        } else {
          console.error('Error fetching inferences: Status code', response.status);
        }
      } catch (error) {
        console.error('Error fetching inferences:', error);
      }
    };

    // Fetch data initially
    fetchData();

    // Set up an interval to fetch data periodically
    const interval = setInterval(fetchData, 1000); // Fetch every 5 seconds

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const processChartData = (data) => {
    const chartData = {
      labels: [],
      quantities: [],
      confidences: []
    };

    const aggregatedData = data.inferences.reduce((acc, inference) => {
      const { type, quantity, confidence } = inference;
      if (!acc[type]) {
        acc[type] = { quantity: 0, confidenceSum: 0, count: 0 };
      }
      acc[type].quantity += quantity;
      acc[type].confidenceSum += confidence;
      acc[type].count += 1;
      return acc;
    }, {});

    for (const type in aggregatedData) {
      chartData.labels.push(type);
      chartData.quantities.push(aggregatedData[type].quantity);
      chartData.confidences.push(aggregatedData[type].confidenceSum / aggregatedData[type].count);
    }

    return chartData;
  };

  const chartData = data ? processChartData(data) : { labels: [], quantities: [], confidences: [] };

  const barChartData = {
    labels: chartData.labels,
    datasets: [
      {
        label: 'Quantidade',
        data: chartData.quantities,
        backgroundColor: [
          '#4DD1FF',
          '#2D99FF',
          '#065DFF',
          '#003EB3',
          '#25347B',
          '#7C588D'

        ],
        borderWidth: 0,
      }
    ]
  };

  const pieChartData = {
    labels: chartData.labels,
    datasets: [
      {
        data: chartData.quantities, // Use quantities for pie chart data
        backgroundColor: [
          '#4DD1FF',
          '#2D99FF',
          '#065DFF',
          '#003EB3',
          '#25347B',
          '#7C588D'

        ],
        borderWidth: 0,
      }
    ]
  };

  return (
    <div className='inferences'>
      {data ? (
        <div className='charts'>
          <div className='bar_Chart'>
            <p>Quantidade de Inferências</p>
            <Bar data={barChartData} options={{ responsive: true }} />
          </div>
          
          <div className='pie_Chart'>
            <p>Indentificação de Elementos</p>
            <Doughnut data={pieChartData} options={{ responsive: true }} />
          </div>
        </div>
      ) : (
        <p>Loading inferences...</p>
      )}
    </div>
  );
}

export default Inferences;
